<template>
  <div class="applyrefund">
    <title-top>申请退款</title-top>

    <div class="row between align-center bg-white" v-for="(item,i) in res.items" :key="i">
      <img class="imgsize176 br4 ml25 mr16 mt25 mb25" :src="item.image_url" alt />
      <div class="flex1 column font24 f-333333 mt25 mb25">
        <p class="mr25 ellipsis">{{item.product_name}}</p>
        <div class="row between f-999999 mt16 align-center">
          <span>{{item.specification_values | spec}}</span>
          <span class="ml25 mr25">×{{item.quantity}}</span>
        </div>
      </div>
    </div>
    <div class="mb20"></div>
    <div class="cloumn bg-white">
      <div class="row between align-center font28 h96" @click="onRefund">
        <div class="row">
          <span class="ml25 f-1A1A1A">退款原因：</span>
          <span class="f-999999">{{reason_tv}}</span>
        </div>
        <img class="imgsize24 mr25" src="~images/zyimg/nextbtn.png" alt />
      </div>
      <div class="line"></div>
      <div class="row align-center h96">
        <span class="ml25 font28 f-1A1A1A">退款金额：</span>
        <div class="font32 mr25 f-EE7C3E">
          ￥{{res.total_payment_amount}}
          <span class="font28"></span>
        </div>
      </div>
    </div>
    <div class="row align-center font28 f-999999 h96">
      <span class="ml25">最多￥{{res.paid_amount}}，含发货邮费￥0.00</span>
    </div>
    <div class="row align-center bg-white h96 font28 f-1A1A1A">
      <span class="ml25">退款说明：</span>
      <input class="flex1 f-999999 mr25" placeholder="选填" v-model="remark" type="text" />
    </div>
    <div class="line"></div>
    <div class="column just-center bg-white">
      <span class="font28 f-1A1A1A ml25 mt25">上传凭证</span>

      <div class="row align-center flex-wrap mb25">
        <van-uploader v-model="fileList" :after-read="afterRead" class="ml25 mt25" />
      </div>
    </div>
    <div class="row flex-center">
      <div class="btn-red mt170" style="background-color:#FF8E61;" @click="onSubmit">提交</div>
    </div>
    <!-- 遮罩 -->
    <div class="column flex-end model-mask" v-if="isShow">
      <div class="column bg-white w100" style="max-width:750px;">
        <div class="row align-center bg-white h80" style="bottom:0;">
          <div class="flex1 row font36 f-666666 flex-center">退款原因</div>
          <img class="imgsize32 mr25" @click="onHid" src="~images/zyimg/delbtn.png" alt />
        </div>
        <div
          class="row align-center bg-white h72"
          v-for="(item,index) in reasons"
          :key="index"
          @click="onSelect(index,item)"
        >
          <img
            v-if="current!=index"
            class="imgsize32 ml25 mr16"
            src="~images/zyimg/not-selblack.png"
            alt
          />
          <img v-else class="imgsize32 ml25 mr16" src="~images/zyimg/selecticon.png" alt />
          <span class="font32 f-1A1A1A">{{item}}</span>
        </div>
        <div class="bg-white h40"></div>
        <div class="row flex-center font36 f-white h80 bg-FF8E61" @click="onSure">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleTop from "@/components/common/title/TitleTop";
import { params } from "@/common/const";
import { refund_reasons, orderDetail } from "network/igou2";
import Vue from "vue";
import axios from "axios";
import { Uploader } from "vant";
Vue.use(Uploader);
export default {
  name: "ApplyRefund",
  data() {
    return {
      isShow: false,
      isSelect: false,
      reasons: [],
      remark: "",
      current: -1,
      realcurrent: -1,
      order_id: this.$route.query.order_id,
      reason_tv: "请选择退款原因",
      res: {},
      credentials: [],
      fileList: [],
      need_return: this.$route.query.need_return
    };
  },
  filters: {
    spec(specification_values) {
      let str = "";
      specification_values.forEach(item => {
        str += item.content + ",";
      });
      if (str) {
        str.substring(0, str.length - 1);
      }
      return str;
    }
  },
  mounted() {
    this._orderDetail();
    this._refund_reasons();
  },
  methods: {
    onSubmit() {
      let formData = new FormData();
      formData.append("order_id", this.order_id);
      formData.append("order_type", "order");
      formData.append("need_return", this.need_return);
      formData.append("amount", this.res.total_payment_amount);
      formData.append("reason", this.reason_tv);
      formData.append("remark", this.remark);
      this.credentials.forEach(item => {
        formData.append("credentials[]", item);
      });

      let base_url = params.debug ? params.api_test_url : params.api_base_url;
      let token = localStorage.getItem("token");
      let config = {
        headers: {
          //添加请求头
          Authorization: token,
          "Content-Type": "multipart/form-data",
          Accept: "application/json"
        }
      };
      console.log(formData);
      axios.post(base_url + "refunds", formData, config).then(
        res => {
          console.log(res);
          this.$toast.show("提交成功");
          this.$router.push({path:'/zyrefundhandle',query:{id:res.data.data.id}});
        },
        err => {
          this.$toast.show(err.response.data.error);
        }
      );
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      this.credentials = [];
      this.fileList.forEach(item => {
        this.credentials.push(item.file); //第一个参数字符串可以填任意命名，第二个根据对象属性来找到file
      });
    },
    onSure() {
      this.realcurrent = this.current;
      this.reason_tv = this.reasons[this.current];
      this.isShow = false;
    },
    _orderDetail() {
      orderDetail(this.order_id).then(res => {
        console.log(res);
        this.res = res;
      });
    },
    _refund_reasons() {
      refund_reasons(this.need_return).then(res => {
        console.log(res);
        this.reasons = res.data;
      });
    },
    onHid() {
      this.isShow = false;
    },
    onRefund() {
      this.current = this.realcurrent;
      this.isShow = true;
    },
    onSelect(index, item) {
      this.current = index;
      //this.reason_tv = item;
      /*
      let list = this.reasons;
      let res = this.res;
      let list = this.res.data;
      list[index].isSelected = !list[index].isSelected;
      res.data = list;
      this.res = res;
      if (this.isSelect) {
        this.isSelect = false;
      } else {
        this.isSelect = true;
      }
      */
    }
  },
  components: {
    TitleTop
  }
};
</script>
<style  scoped>
.hd-border {
  width: 1.96rem;
  height: 0.76rem;
  border: 1px solid #999999;
}
</style>
